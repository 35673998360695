<template>
    <div id="container" >
        <div id="contacts">
        <div id="contacts--contact">
            <p> <label class="negrita">Correo: </label>  {{ mail }}</p>
            <p> <label class="negrita">Celular: </label>  {{ celular }}</p>
            <p><label class="negrita">Telefono: </label>  {{ phone }}</p>
            <p><label class="negrita">Dirección: </label>    {{ direccion }}</p>
        </div>
        <div id="contacts--social">

          <b-container class="redes_sociales">  
            <b-row>
              <b-col md>
                <a href="https://www.facebook.com/fundacion.infocal">
                  <b-img :src="require('@/assets/social/facebook.svg')" fluid alt="Facebook"></b-img>
                </a>
              </b-col>
              <b-col md>
                <a href="https://twitter.com/InfocalP">
                 <b-img :src="require('@/assets/social/twitter.svg')"  fluid alt="Twitter"></b-img> 
                </a>
              </b-col>
              <b-col md>
                <a href="https://www.youtube.com/channel/UCByUVOcQ7v6pQl3fH_FOUqg"> 
                  <b-img :src="require('@/assets/social/youtube.svg')"  fluid alt="youtube"></b-img>
                </a>
              </b-col>
              <b-col md>
                <a href="https://www.instagram.com/infocalpotosi/"> 
                  <b-img :src="require('@/assets/social/instagram.svg')"  fluid alt="Instagram"></b-img> 
                </a>
              </b-col>
            </b-row>
          </b-container>
       

        </div>
        </div>
        <div id="mentions">
        <p>{{ mention }}</p>
        </div>
    </div>
</template>
<script>

export default {
  name: 'pie',
  data(){
      return{
      mail : 'info@infocalpotosi.edu.bo',
      celular: '70460777' ,
      phone: '62 2 2380' ,
      direccion: 'Linares nro. 123 esq. Topater Plaza Avaroa',
      mention: '2021 | Copyright Fundación Infocal Potosí'
      }
  },
  components: {
       
        },
  props: {
    msg: String
  }
}
</script>

<style>
#container{
  padding-top:20px;
  margin-top:20px;
  width:100%;
  color:#e5eee9;
  /* background-color:#292354; */
 /* --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; */
  background: linear-gradient(180deg,#0d6efd,#4777b9); 
   
  display:flex;
  flex-direction:column;
  align-items:center;
  
}
#contacts{
  display:flex;
  justify-content:space-around;
  align-items:center;
  width:100%;
}
#contacts--contact{
  text-align:right;
  
}

#contacts--contact p {
    margin-bottom: 0.1rem;
    font-size:0.8rem;
}


#mentions p {
  font-size:0.5em;
  margin-top: 0.5rem;
}

.negrita{
  color: #c6c9c7;
  margin-right: 0.7rem;
}
</style>