import Vue from 'vue'
import VueRouter from 'vue-router'
/* import Home from '../views/Home.vue' */

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      Auth: false, title: 'Fundación Infocal Potosí'
    },
    //component: Home
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
  },
  {
    path: '/carreras',
    name: 'carreras',
    meta: {
      Auth: false, title: 'Carreras de Formación'
    },
    component: () => import(/* webpackChunkName: "carreras" */ '../views/Carreras.vue')
  },
  {
    path: '/cursos',
    name: 'cursos',
    meta: {
      Auth: false, title: 'Cursos de Capacitación'
    },
    component: () => import(/* webpackChunkName: "cursos" */ '../views/Cursos.vue')
  },
  {
    path: '/aulavirtual',
    name: 'aulavirtual',
    meta: {
      Auth: false, title: 'Aula Virtual'
    },
    beforeEnter() {                    
      window.open("http://www.infocalpotosi.edu.bo/aulavirtual", 
      '_blank');
       },
    //component: () => import(/* webpackChunkName: "cursos" */ '../views/cursos.vue')
  },

  {
    path: '/about',
    name: 'about',
    meta: {
      Auth: false, title: 'Acerca de nosotros'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router


router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});