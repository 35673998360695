<template>
<div>
  <b-navbar toggleable="md" type="dark" variant="primary">
    <b-container>
    <b-navbar-brand :to="{name: 'Home'}" exact>
            <img src="@/assets/logo.png" alt="Kitten"> 
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse" ></b-navbar-toggle>
    <b-collapse  id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item :to="{name: 'carreras'}" exact>Carreras</b-nav-item> 
        <b-nav-item :to="{name: 'cursos'}" exact>Cursos</b-nav-item>
        <b-nav-item :to="{name: 'aulavirtual'}" exact>Aula Virtual</b-nav-item>
        <b-nav-item :to="{name: 'about'}" class="fs-6" exact>Conócenos más</b-nav-item>
      </b-navbar-nav>
    </b-collapse> 
    </b-container>
  </b-navbar>
</div>

</template>

<script>
export default {
    name: 'menuprincipal'
}
</script>
<style>

#app{
  font-family: Montserrat,Helvetica,sans-serif;
    -webkit-font-smoothing: antialiased;
}



/* CONFIGURACIÓN DE COLORES EN EL MENU */
.nav-link {
color:rgb(229, 238, 233)!important;
/* margin: 5 0 5 0; */

}
.nav-link:focus {
color:rgb(110, 166, 243)!important
}
.nav-link:hover {
color:rgb(110, 166, 243)!important
}
.nav-link:active {
color:rgb(110, 166, 243)!important
}

/* .router-link-active {
color:rgb(20, 19, 19)!important
} */

.router-link-exact-active{
color:rgb(110, 166, 243)!important
}

.navbar-brand:focus {
color:rgb(4, 224, 151)!important
}
.navbar-brand:active {
color:rgb(4, 224, 151)!important
}

.navbar-brand.router-link-exact-active{
color:rgb(190, 93, 255)!important
}

/* CONFIGURACIÓN PARA ALINEACIÓN, MARGEN */
.navbar-expand-md {
  justify-content: space-between !important;
  background: linear-gradient(0deg,#0d6efd,#5689cf); 
  transform: translateY();

}
.navbar-collapse{
  flex-grow: inherit !important;
}
.nav-item{
  margin-left: 12px;
}


/* .menuvisible{
  position: fixed;
  width: 100%;
  
} */

</style>