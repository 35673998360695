<template>
  <div id="app">
    <header>
      <menuprincipal></menuprincipal>
  
    </header>
    
    <router-view  class="v-content"/>
    <footer>
      <pie></pie>
    </footer>

  </div>
</template>

<script>
import menuprincipal from './components/menuprincipal.vue'
import Pie from './components/pie.vue'
export default {
  
  components: { menuprincipal, Pie, },

}
</script>

<style>

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;
    
  

}

#app{
    display: flex;
    flex-direction: column; 
}


header{
  
  position: fixed;
    width: 100%;
    right: 0;
    left: 0;
    box-shadow: 0 2px 5px rgb(45 172 225 / 20%), 0 3px 6px rgb(0 191 209 / 40%);
    z-index: 10;  
}

footer{
  width: 100%;
}


.v-content  {
    margin-top: 60px;
    flex: auto;
    width: 100%; 
} 


</style>
